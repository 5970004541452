import { Grid, Link, Typography } from "@mui/material"
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./SplineCharts.scss";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import { useAPI } from "../../../shared/services/api/API";
import { OPTIVAL_CONSTANTS } from "../../../shared/Constants";

export const SplineCharts = () => {
  const currentYear = new Date().getFullYear();
    const { t } = useTranslation();
    const { httpGet, handleAlertBar } = useAPI();
    const [formatData, setFormatData] = useState<any>({});
    const [selectedChart, setSelectedChart] = useState(t("opva.totalUsers"));

    const getUsersMonthlyData = () => {
      httpGet(OPTIVAL_CONSTANTS.API_URLS.GET_TOTAL_USER_LINECHART).then(
        (response) => {
          if (response && response.data) {
            if (response.data.error) {
              handleAlertBar("error", response.data.message);
            } else {
              // set monthly graph 
            const result= transformData(response?.data) 
            setFormatData(result);
            }
          } else {
            handleAlertBar("error", t("opva.serviceUnavailable"));
          }
        }
      );
    };

    const getSkillsMonthlyData = () => {
      httpGet(OPTIVAL_CONSTANTS.API_URLS.GET_TOTAL_SKILL_LINE_CHART).then(
        (response) => {
          if (response && response.data) {
            if (response.data.error) {
              handleAlertBar("error", response.data.message);
            } else {
              // set monthly graph 
            const result= transformData(response?.data) 
            setFormatData(result);
            }
          } else {
            handleAlertBar("error", t("opva.serviceUnavailable"));
          }
        }
      );
    };

    const getWorkflowsMonthlyData = () => {
      httpGet(OPTIVAL_CONSTANTS.API_URLS.GET_TOTAL_WORKFLOW_LINECHART).then(
        (response) => {
          if (response && response.data) {
            if (response.data.error) {
              handleAlertBar("error", response.data.message);
            } else {
              // set monthly graph 
            const result= transformData(response?.data) 
            setFormatData(result);
            }
          } else {
            handleAlertBar("error", t("opva.serviceUnavailable"));
          }
        }
      );
    };

    const transformData = (data: any) =>{
      const resp: any = {};
      for (const [key, value] of Object.entries(data)) {
        const [month, year] = key.split('-');
        if(!resp[year]) resp[year] = [];
        resp[year]?.push(value);
      }
      console.log(resp)
      return resp;
    }

    const handleChartTitle = (title: string) => {
      setSelectedChart(title);
    }
  
    useEffect(() => {
      if(selectedChart === t("opva.totalUsers")) {
        getUsersMonthlyData();
      } else if(selectedChart === t("opva.totalWorkflow")) {
        getWorkflowsMonthlyData();
      } else {
        getSkillsMonthlyData();
      }
    }, [selectedChart]);

    const options = {
      chart: {
        type: 'spline',
        backgroundColor: "#F7F9FB",
        height:300,
      },
      title: {
          text: null,
          align: 'left'
      },
      xAxis: {
        categories: [
          'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
          'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
      ],
      lineColor: 'transparent',
    },
    yAxis: {
        title: {
            text: ''
        },
        gridLineColor: 'transparent'
    },
      legend: {
          layout: 'horizontal',
          align: 'left',
          x: 320,
          verticalAlign: 'top',
          y: -20,
      },
      credits: {
        enabled: false
    },
      series: [{
          name: t("opva.lastYear"),
          data: formatData[currentYear - 1],
          marker: {
              enabled: false
          },
          dashStyle: 'dash',
          lineColor: "#A8C5DA"
  
      }, {
          name: t("opva.thisYear"),
          data: formatData[currentYear],
          marker: {
            enabled: false,
          },
        type: "areaspline",
        fillOpacity: 0.1,
        color: "#1C1C1C"
      }],
  }
  return (
    <div className="spline-chart-container mt-1">
      <span className="m-2 chart-container-title">
        <Typography variant={selectedChart === t("opva.totalUsers") ? 'bodyNormal' : 'bodyNormal'} className={`mx-2 ${selectedChart === t("opva.totalUsers") ? 'fw-bold' : 'label-grey'}`} onClick={()=>handleChartTitle(t("opva.totalUsers"))}>{t("opva.totalUsers")}</Typography>
        <Typography variant={selectedChart === t("opva.totalWorkflow") ? 'bodyNormal' : 'bodySmall'} className={`mx-2 ${selectedChart === t("opva.totalWorkflow") ? 'fw-bold' : 'label-grey'}`} onClick={()=>handleChartTitle(t("opva.totalWorkflow"))}>{t("opva.totalWorkflow")}</Typography>
        <Typography variant={selectedChart === t("opva.totalSkills") ? 'bodyNormal' : 'bodySmall'} className={`mx-2 ${selectedChart === t("opva.totalSkills") ? 'fw-bold' : 'label-grey'}`} onClick={()=>handleChartTitle(t("opva.totalSkills"))}>{t("opva.totalSkills")}</Typography>
      </span>
      <HighchartsReact
              highcharts={Highcharts}
              options={options}
          />
  </div>
  )
}